<script setup lang="ts">

</script>

<template>
    <router-view/>
</template>

<style lang="less">

</style>
