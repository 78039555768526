import { FORTUNE_TYPE } from './constant';

/** 根据url获取参数 */
export const getParamsFromUrl = (url: string): AnyObject => {
    let paramStr = url.split('?')[1];
    if (!paramStr) return {};
    if (paramStr.indexOf('#/') > -1) paramStr = paramStr.split('#/')[0];
    let kavs = paramStr.split('&');
    let result: AnyObject = {};
    kavs.forEach(item => {
        let kv = item.split('=');
        result[kv[0]] = kv[1];
    })
    return result;
}

/** 获取运势类型 */
export const getFortuneType = (withAll?: boolean): FortuneType[] => {
    if (!withAll) return FORTUNE_TYPE;
    let types = FORTUNE_TYPE.slice();
    types.unshift({
        label: '综合运势',
        text: '综合',
        type: 'all',
        icon: require('@/assets/images/xz/ys/icon_all.png'),
        color: '#FF94DF',
        bgColor: '#FFE5FE',
        value: '',
        content: ''
    })
    return types;
}

/** 获取随机数量字符串 */
export const randomString = (len: number) => {
    len = len || 32;
    var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
    for (let i = 0; i < len; i++) {
        let index = Math.floor(Math.random() * a);
        let str = t.substr(index, 1);
        n += str;
    }
    return n;
};
