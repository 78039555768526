export default {
    setItem(key: string, value: any) {
        if (typeof value == 'object') {
            value = JSON.stringify(value);
        } else if (value == null || value == undefined) {
            value = '';
        } else {
            value = '' + value;
        }
        window.sessionStorage.setItem(key, value);
    },
    getItem(key: string) {
        return window.sessionStorage.getItem(key);
    },
    removeItem(key: string) {
        window.sessionStorage.removeItem(key);
    },
    clear() {
        window.sessionStorage.clear();
    }
}
