import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import XzSelect from '../views/XzSelect.vue';
import { Haa, OeReportType, OeReportSubType } from '@haa/fwk-h5/lib/index';
const { oeReport } = Haa.util().useOeReport();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Guide',
        component: XzSelect,
        meta: {
            spaceKeys: ['Guide_native', 'Guide_banner'],
        },
    },
    {
        path: "/index",
        name: "Index",
        component: HomeView,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('../views/home/home.vue'),
                meta: {
                    spaceKeys: ['Home_inter', 'Home_native_inter', 'Home_dialog', 'Home_banner', 'Home_top', 'Home_center'],
                },
            },
            {
                path: '/analysis',
                name: 'Analysis',
                component: () => import('../views/home/analysis.vue'),
                meta: {
                    spaceKeys: ['Home_banner', 'Analysis_top', 'Analysis_center'],
                },
            },
            {
                path: '/answer',
                name: 'Answer',
                component: () => import('../views/home/answer.vue'),
                meta: {
                    spaceKeys: ['Home_banner'],
                },
            },
            {
                path: '/topic',
                name: 'Topic',
                component: () => import('../views/home/topic.vue'),
                meta: {
                    spaceKeys: ['Home_banner', 'Topic_top'],
                },
            }
        ],
    },
    {
        path: '/fortune-detail',
        name: 'FortuneDetail',
        component: () => import('../views/FortuneDetail.vue'),
        meta: {
            spaceKeys: ['Home_inter', 'FortuneDetail_day_top', 'FortuneDetail_week_top', 'FortuneDetail_month_top'],
        },
    },
    {
        path: '/analysis-detail',
        name: 'AnalysisDetail',
        component: () => import('../views/AnalysisDetail.vue'),
        meta: {
            spaceKeys: ['Home_inter', 'AnalysisDetail_analysis_top', 'AnalysisDetail_fortune_top'],
        },
    },
    {
        path: '/answer-detail',
        name: 'AnswerDetail',
        component: () => import('../views/AnswerDetail.vue'),
        meta: {
            spaceKeys: ['AnswerDetail_inter', 'AnswerDetail_banner'],
        },
    },
    {
        path: '/topic-list',
        name: 'TopicList',
        component: () => import('../views/TopicListView.vue'),
        meta: {
            spaceKeys: ['TopicList_top'],
        },
    },
    {
        path: '/topic-detail',
        name: 'TopicDetail',
        component: () => import('../views/TopicDetailView.vue'),
        meta: {
            spaceKeys: ['Home_inter', 'TopicDetail_banner'],
        },
    },
    {
        path: '/topic-result',
        name: 'TopicResult',
        component: () => import('../views/TopicResultView.vue'),
        meta: {
            spaceKeys: ['TopicResult_native', 'TopicResult_banner'],
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const { useParams } = require('@/store/useParams');
    const { params } = useParams();
    if (to.path == '/' && params.path == 'fortune') {
        next('/fortune-detail?zodiac=白羊座');
    } else if (to.path == '/' && params.path == 'analysis') {
        next('/analysis-detail?zodiac=白羊座');
    } else if (to.path == '/' && (!params.skip || params.skip == 0)) {
        oeReport(OeReportType.Action, OeReportSubType.BtnClick, 'Guide', '没有skip参数直接上报');
        next('/home');
    } else {
        next();
    }
})

export default router;
