import { ref, reactive, computed } from 'vue';
import { XZ_LIST } from '@/utils/constant';
import session from '@/utils/session';


/** 默认星座 */
const defaultActive = ref<number>(-1);
let date = new Date(), month = date.getMonth() + 1, day = date.getDate();
XZ_LIST.forEach((item, index) => {
    let range = item.time.split('~');
    let start = range[0], end = range[1];
    let startMonth = +start.split('.')[0], startDay = +start.split('.')[1];
    let endMonth = +end.split('.')[0], endDay = +end.split('.')[1];
    if ((month == startMonth && day >= startDay) || month == endMonth && day <= endDay) {
        defaultActive.value = index;
    }
})
/** 当前选择的星座序号 */
const active = ref<number>(defaultActive.value);

/** 星座列表 */
const xzList = reactive(XZ_LIST);
const handleChange = (index: number) => {
    active.value = index;
    handleConfirm();
}
/** 确认的星座，-1：展示页面选择，-2：展示弹窗选择 */
let cache = session.getItem('CONFIRM_INDEX');
const confirm = ref<number>(cache ? +cache : -1);
/** 当前确认的星座对象 */
const currentXz = computed((): XzInfo => {
    if (confirm.value < 0) return xzList[defaultActive.value];
    return xzList[confirm.value];
})
const handleConfirm = () => {
    confirm.value = active.value;
    session.setItem('CONFIRM_INDEX', confirm.value);
}
const handleSkip = () => {
    confirm.value = defaultActive.value;
    session.setItem('CONFIRM_INDEX', confirm.value);
}
const handleReset = () => {
    confirm.value = -2;
    session.setItem('CONFIRM_INDEX', confirm.value);
}

export function useXz() {
    return { defaultActive, active, confirm, currentXz, xzList, handleChange, handleConfirm, handleSkip, handleReset }
}
